import colors from '@/scss/_colors.scss';

export default category => {
  const programCardLabelColor = colors[`color_category_${category}_label`];
  const programIntroBackgroundImage = require(`@/assets/program/intro-header-bg-${category}.png`);
  const programIntroDoodle = require(`@/assets/program/intro-doodle-${category}.png`);
  const programIntroCharacter = require(`@/assets/program/intro-character-${category}.png`);
  const programDetailBackgroundImage = require(`@/assets/program/header-bg-${category}.svg`);

  return {
    programCardLabelColor,
    programIntroBackgroundImage,
    programIntroDoodle,
    programIntroCharacter,
    programDetailBackgroundImage,
  };
};
